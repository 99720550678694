import Image from "next/image";
import { FC, ReactNode } from "react";
interface FooterInfoItemProps {
  icon: string;
  title: string | ReactNode;
  description?: ReactNode;
}
export const FooterInfoItem: FC<FooterInfoItemProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="flex items-start gap-x-4 mb-2 text-[12px] lg:text-[14px]">
    <Image
      src={icon}
      alt={typeof title === "string" ? title : "footer info"}
      width={17.42}
      height={17.42}
    />
    <div>
      {typeof title === "string" ? (
        <div className="font-bold">{title}</div>
      ) : (
        title
      )}
      {description && <div>{description}</div>}
    </div>
  </div>
);
