import Image from "next/image";
import { FunctionComponent } from "react";
import { SectionTitle } from "./section-title";
import { SectionContainer } from "./section-container";

export const Benefits: FunctionComponent = () => {
  const renderBenefits = () => {
    return data.map(({ title, description, illustrationImg }, key) => {
      return (
        <div key={key} className="flex flex-col items-center justify-between min-w-[200px] h-[286px]">
          <div>
            <Image src={illustrationImg} width={200} height={200} alt="illustration" className="p-4 mt-8 md:p-0 md:mt-0" />
          </div>
          <div className="flex flex-col">
            <span className="text-[18pt] md:text-[28px] font-bold mb-2 text-center leading-[1.8rem]">{title}</span>
            <span className="text-[12pt] md:text-[18px] text-center leading-[1.4rem]">{description}</span>
          </div>
        </div>
      );
    });
  };
  return (
    <SectionContainer>
      <SectionTitle title="Kenapa Konsinyasi Menguntungkan?" />
      <div className="flex flex-col md:flex-row gap-4 md:gap-16 w-full">
        {renderBenefits()}
      </div>
    </SectionContainer>
  );
};

const data = [
  {
    title: "Tanpa Biaya di Muka",
    description: "Mulai jualan tanpa perlu khawatir biaya di awal",
    illustrationImg: "/consignment/img/illustration-best-price.svg",
  },
  {
    title: "Barang dapat Dikembalikan",
    description: "Kembalikan barang kapanpun jika tidak sesuai",
    illustrationImg: "/consignment/img/illustration-ready-stock.svg",
  },
  {
    title: "Foto Produk Disediakan",
    description: "Kami akan bantu sediakan foto produknya sekaligus",
    illustrationImg: "/consignment/img/illustration-premium-quality.svg",
  },
];