"use client";
import Link from "next/link";
import Image from "next/image";
import { FooterInfoItem } from "../landing/components/footer-info-item";
import { useOsPlatform } from "../../hooks/use-os-platform";

export const Footer = () => {
  const { isIos, isAndroid, isDefault, searchParams } = useOsPlatform();

  return (
    <footer className="flex flex-col w-full z-20 bg-black text-white items-center px-4 md:px-2">
      <div className="flex flex-col lg:grid lg:grid-cols-4 justify-center lg:justify-between w-full max-w-[1280px] md:mb-8">
        <div className="flex flex-col justify-between text-[12px] lg:text-[20px] items-start gap-y-4 pt-8 md:pt-0">
          <div className="flex items-center justify-between w-full">
            <Image
              src="/consignment/img/logo-white.svg"
              className="hidden md:block py-16"
              alt="logo"
              width={190}
              height={46.82}
            />
            <Image
              src="/consignment/img/logo-white.svg"
              className="block md:hidden"
              alt="logo"
              width={120}
              height={46.82}
            />
            <Image
              src="/consignment/img/iso-white.svg"
              width={96}
              height={47}
              alt="ISO"
              className="block md:hidden"
            />
          </div>
          <div className="lg:hidden flex pt-4 text-[14px] leading-[21.6px] text-white">
            Jooalan adalah platform B2B Commerce, tempatnya online seller
            belanja berbagai kategori produk dengan kualitas dan harga terbaik.
            Mulai cuanmu bersama Joalan sekarang!
          </div>
          <div className="hidden lg:flex lg:flex-col text-white gap-y-2 mt-0 md:-mt-12">
            <div className="font-[700]">Unduh Aplikasi Sekarang!</div>
            <div className="flex items-center gap-x-1">
              {(isAndroid || isDefault) && (
                <Link href={process.env.NEXT_PUBLIC_PLAY_STORE ?? "/"}>
                  <Image
                    src="/landing/img/play-store.png"
                    width={147}
                    height={57}
                    alt="Play Store"
                    className="cursor-pointer"
                  />
                </Link>
              )}
              {(isIos || isDefault) && (
                <Link href={process.env.NEXT_PUBLIC_APP_STORE ?? "/"}>
                  <Image
                    src="/landing/img/app-store.png"
                    width={147}
                    height={44}
                    alt="App Store"
                    className="cursor-pointer"
                  />
                </Link>
              )}
            </div>
          </div>
          <Image
            src="/consignment/img/iso-white.svg"
            width={147}
            height={57}
            alt="ISO"
            className="hidden md:block"
          />
        </div>

        <div className="hidden lg:flex flex-col justify-between col-span-2 px-0 lg:px-8 pt-4 lg:pt-0">
          <div className="flex pt-2 md:pt-16 text-[18px] leading-[21.6px]">
            Jooalan adalah platform B2B Commerce, tempatnya online seller
            belanja berbagai kategori produk dengan kualitas dan harga terbaik.
            Mulai cuanmu bersama Joalan sekarang!
          </div>
          <div className="flex">
            <Link href="https://www.tiktok.com/@jooalan.id" target="_blank">
              <Image
                src="/consignment/img/tiktok.svg"
                alt="tiktok"
                width={44}
                height={44}
                className="cursor-pointer"
              />
            </Link>
            <Link href="https://www.youtube.com/@jooalan" target="_blank">
              <Image
                src="/consignment/img/yt.svg"
                alt="youtube"
                width={44}
                height={44}
                className="cursor-pointer"
              />
            </Link>
            <Link href="https://www.instagram.com/jooalan.id/" target="_blank">
              <Image
                src="/consignment/img/ig.svg"
                alt="instagram"
                width={44}
                height={44}
                className="cursor-pointer"
              />
            </Link>
          </div>
        </div>

        <div className="flex flex-col lg:pl-4 pt-2 md:pt-16">
          <FooterInfoItem
            icon="/consignment/img/map-white.svg"
            title="Gudang"
            description={
              <div>
                <div>Waringin Warehouse Kosambi Permai</div>
                <div>blok RQ Jatimulya, Kec. Kosambi</div>
                <div>Kota Tangerang</div>
              </div>
            }
          />
          <FooterInfoItem
            icon="/consignment/img/map-white.svg"
            title="Kantor"
            description={
              <div>
                <div>GoWork Senayan City - Panin Tower Lt.12.</div>
                <div>Jl. Asia Afrika No.Lot. 19, Daerah Khusus Ibukota Jakarta 10270</div>
              </div>
            }
          />
          <FooterInfoItem
            icon="/consignment/img/mail-white.svg"
            title={
              <a className="font-bold" href="mailto:cuan@jooalan.id">
                cuan@jooalan.id
              </a>
            }
          />
          <FooterInfoItem
            icon="/consignment/img/phone-white.svg"
            title={
              <a className="font-bold" href="tel:081910000118">
                0819-10000-118
              </a>
            }
          />
        </div>
        <div className="flex lg:hidden mb-4 md:mb-0 mx-0">
          <Link href="https://www.tiktok.com/@jooalan.id" target="_blank">
            <Image
              src="/consignment/img/tiktok.svg"
              alt="tiktok"
              width={44}
              height={44}
              className="cursor-pointer"
            />
          </Link>
          <Link href="https://www.youtube.com/@jooalan" target="_blank">
            <Image
              src="/consignment/img/yt.svg"
              alt="youtube"
              width={44}
              height={44}
              className="cursor-pointer"
            />
          </Link>
          <Link href="https://www.instagram.com/jooalan.id/" target="_blank">
            <Image
              src="/consignment/img/ig.svg"
              alt="instagram"
              width={44}
              height={44}
              className="cursor-pointer"
            />
          </Link>
        </div>
        <div className="flex flex-col lg:hidden items-start gap-y-2 mt-4">
          <div className="font-bold text-[16px]">Unduh Aplikasi Sekarang!</div>
          <div className="flex items-center gap-x-1">
            {(isAndroid || isDefault) && (
              <Link href={process.env.NEXT_PUBLIC_PLAY_STORE ?? "/"}>
                <Image
                  src="/landing/img/play-store.png"
                  width={114}
                  height={52}
                  alt="Play Store"
                  layout="fixed"
                />
              </Link>
            )}
            {(isIos || isDefault) && (
              <Link href={process.env.NEXT_PUBLIC_APP_STORE ?? "/"}>
                <Image
                  src="/landing/img/app-store.png"
                  width={118}
                  height={40}
                  alt="App Store"
                  layout="fixed"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center bg-black py-2 text-white">
        <div className="py-2 text-[10px] lg:text-[14px]">
          © Copyright 2023 PT Ruang Dagang Internasional
        </div>
        <div className="flex text-[8px] lg:text-[10px] gap-x-1">
          <Link href={`/terms-and-conditions?${searchParams.toString()}`}>
            <span className="cursor-pointer">Terms and Conditions</span>
          </Link>{" "}
          &bull;
          <Link href={`/privacy-policy?${searchParams.toString()}`}>
            <span className="cursor-pointer">Privacy Policy</span>
          </Link>{" "}
          &bull;
          <Link href={`/faq?${searchParams.toString()}`}>
            <span className="cursor-pointer">FAQ</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};
