import { FunctionComponent } from "react";
import { SectionTitle } from "./section-title";
import { SectionContainer } from "./section-container";

export const Recommendation: FunctionComponent = () => {
  const renderRecommendations = () => {
    return data.map(({ title, description }, key) => {
      return (
        <div key={key} className="flex flex-col bg-primary/5 p-6 md:p-8 rounded-4xl gap-y-4 relative overflow-hidden">
          <div className="w-20 md:w-24 h-20 md:h-24 bg-primary rounded-full flex justify-center items-center font-bold text-[28px] md:text-[40px] text-white absolute -right-3 -top-3 z-10">{key + 1}.</div>
          <div className="w-24 md:w-28 h-24 md:h-28 bg-jooalan-secondary rounded-full absolute -right-4 md:-right-2 -top-7 z-0" />
          <span className="text-[18px] md:text-[24px] leading-[1.4rem] md:leading-[1.6rem] font-bold w-3/4">{title}</span>
          <span className="text-[14px] md:text-[18px] leading-[1.2rem] md:leading-[1.4rem]">{description}</span>
        </div>
      );
    });
  };
  return (
    <SectionContainer>
      <SectionTitle>
        <span>Kenapa <i>Reseller</i> Lebih Baik Melakukan Konsinyasi?</span>
      </SectionTitle>
      <div className="flex flex-col md:flex-row gap-4 md:gap-16">
        {renderRecommendations()}
      </div>
    </SectionContainer>
  );
};

const data = [
  {
    title: "Kembangkan Usaha dengan Resiko Rendah",
    description: "Reseller dapat memanfaatkan Layanan Konsinyasi untuk uji coba penjualan produk-produk baru selain dari yang biasa dijual tanpa mengeluarkan biaya pembelian barang di muka. Semakin banyak produk tersedia, potensi meluasnya pangsa pasar akan semakin terbuka.",
  },
  {
    title: "Tidak Khawatir Deadstock atau Barang Nyangkut",
    description: "Reseller tidak perlu khawatir apabila barang yang dikonsinyasikan tidak laku karena barang dapat dikembalikan ke Jooalan. Sangat cocok bagi reseller yang memiliki keterbatasan tempat penyimpanan di toko atau gudang.",
  },
];