import classNames from "classnames";
import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, PropsWithChildren } from "react";

export const SectionContainer: FunctionComponent<PropsWithChildren & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(
      "flex flex-col items-center mb-24 md:mb-64",
      className,
    )}
      {...rest}
    >
      {children}
    </div>
  );
};