import classNames from "classnames";
import Link from "next/link";
import { FiArrowUpRight } from "react-icons/fi";
import { track } from "@vercel/analytics/react";
import { SectionContainer, SectionTitle } from ".";

export const ConsignmentSteps = () => {
  const trackGetCatalog = () => {
    track('get_catalog_from_steps');
  };
  const renderSteps = () => {
    return data.map(({ text, className }, index) => {
      return (
        <div key={index} className={classNames("flex flex-col justify-between p-2 md:p-8 w-full h-full mx-auto", className)}>
          <span className="text-primary text-[16px] md:text-[20px] font-bold mb-12">Langkah-{index + 1}</span>
          <span className="text-[16px] md:text-[22px] font-semibold leading-[1.6rem]">{text}</span>
        </div>
      );
    });
  };
  return (
    <SectionContainer className="bg-gray-100 p-2 md:py-16 md:px-0">
      <SectionTitle title="Langkah Konsinyasi Bersama Jooalan" />
      <div className="grid grid-cols-2 md:grid-cols-3 md:w-2/3">
        {renderSteps()}
        <div className="flex flex-col justify-between border p-3 bg-primary rounded-xl m-2 md:m-4">
          <span className="text-white text-[22px] md:text-[32px] font-bold mb-6 md:mb-12 leading-[1.5rem] md:leading-[2.6rem]">
            Dapatkan Katalog Barang
          </span>
          <Link href="https://wa.link/eda53o" target="_blank" onClick={trackGetCatalog}>
            <span className="text-[24px] bg-jooalan-secondary font-bold rounded-full flex justify-between items-center cursor-pointer">
              <div className="flex justify-center items-center w-full">
                <span className="px-2 md:px-4 text-[12px] md:text-[20px]">Kontak Kami</span>
              </div>
              <div className="bg-jooalan-secondary-60 rounded-full w-8 md:w-14 h-8 md:h-14 flex items-center justify-center p-2">
                <FiArrowUpRight size={38} />
              </div>
            </span>
          </Link>
        </div>
      </div>
    </SectionContainer>
  );
};

const data = [
  { text: 'Pilih produk dari katalog barang', className: 'border-t-0 border-r-2 border-b-2 border-l-0', },
  { text: 'Verifikasi Reseller dan stok barang oleh tim Jooalan', className: 'border-t-0 border-r-0 md:border-r-2 border-b-2 border-l-0', },
  { text: 'Barang dikirimkan ke Reseller', className: 'border-t-0 border-r-2 md:border-r-0 border-b-2 border-l-0', },
  { text: 'Pengecekan rutin stok barang di Reseller oleh tim Jooalan', className: 'border-t-0 border-r-0 md:border-r-2 border-b-2 md:border-b-0 border-l-0', },
  { text: 'Pembayaran barang yang terjual', className: 'border-t-0 border-r-2 border-b-0 border-l-0 md:border-l-0', },
];