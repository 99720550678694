import { useRouter } from "next/router";

const getUrlParams = (params) => {
  return Object.keys(params).reduce(
    (acc, item) => acc + item + "=" + params[item],
    ""
  );
};

export const useOsPlatform = () => {
  const router = useRouter();
  const platform = router.query["from"];
  const searchParams =
    router.query && Object.keys(router.query).length === 0
      ? ""
      : getUrlParams(router.query);
  const isIos = platform === "ios";
  const isAndroid = platform === "android";
  const isDefault = !platform;

  return { isIos, isAndroid, isDefault, searchParams };
};
