import { FunctionComponent, PropsWithChildren } from "react";

interface SectionTitleProps {
  title?: string;
}

export const SectionTitle: FunctionComponent<SectionTitleProps & PropsWithChildren> = ({ children, title }) => {
  return (
    <span className="text-[28px] md:text-[40px] font-bold text-center leading-[2.6rem] mb-8">
      {title ?? children}
    </span>
  );
};