import classNames from "classnames";
import { FunctionComponent } from "react";
import { SectionTitle } from "./section-title";
import { SectionContainer } from "./section-container";

export const USP: FunctionComponent = () => {
  const renderUsp = () => {
    return data.map(({ title, description }, index) => {
      const isEven = index % 2 === 0;
      return (
        <div key={index} className={classNames(
          "flex flex-col w-[320px] border-b-[16px] rounded-2xl bg-gray-100 p-4",
          {
            "border-jooalan-primary": isEven,
            "border-jooalan-secondary": !isEven,
          }
        )}>
          <div className="text-[18pt] font-bold mt-0 md:mt-4 mb-4">{title}</div>
          <div className="text-[12pt] pb-4">{description}</div>
        </div>
      );
    });
  };
  return (
    <SectionContainer>
      <SectionTitle title="Mengapa Konsinyasi Bersama Jooalan?" />
      <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-6 w-full">
        {renderUsp()}
      </div>
    </SectionContainer>
  );
};

const data = [
  {
    title: 'Fleksibel',
    description: 'Anda bisa dengan mudah meminta produk yang ingin Anda jual di toko Anda, dan menukar / mengembalikannya kepada kami apabila ternyata produk tersebut tidak cocok karena alasan apapun, tanpa dikenakan biaya.',
  },
  {
    title: 'Best Price',
    description: 'Puluhan supplier telah bergabung bersama kami untuk memberikan produk terbaik dengan harga terbaik bagi toko Anda.',
  },
  {
    title: 'Mudah',
    description: 'Kami menyediakan gambar dan deskripsi, siap untuk di upload ke toko Anda di marketplace seperti Shopee, Tokopedia, Lazada, dan sebagainya.',
  },
  {
    title: 'Partner Bisnis Ideal',
    description: 'Ketika produk konsinyasi terbukti berhasil, Anda dapat melakukan pembelian putus dengan harga terbaik melalui Jooalan!',
  },
];