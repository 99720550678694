import { track } from "@vercel/analytics/react";
import Image from "next/image";
import Link from "next/link";
import { FunctionComponent } from "react";

export const Hero: FunctionComponent = () => {
  const trackGetCatalog = () => {
    track('get_catalog_main_banner');
  };

  return (
    <section className="relative mb-16 md:mb-56 mt-0 md:mt-8">
      <div className="flex flex-col md:flex-row bg-primary w-full rounded-2xl p-4 md:p-12">
        <div className="flex flex-col items-start w-full md:w-[60%] mt-[260px] md:mt-0 md:pr-24 text-center md:text-left z-10">
          <span className="text-white text-[36px] md:text-[42pt] font-bold mb-4 md:mb-8 leading-[2.6rem] md:leading-[4.2rem] w-full md:w-[80%] mt-8 md:mt-0">Kembangkan Usaha Tanpa Tambahan Modal!</span>
          <span className="text-white text-[14px] md:text-[14pt] mb-2 md:mb-4 leading-2">Jooalan menghadirkan layanan <span className="font-bold">KONSINYASI</span> atau <span className="font-bold">TITIP JUAL</span> untuk para reseller yang berjualan secara online atau offline.</span>
          <span className="text-white text-[14px] md:text-[14pt] mb-2 md:mb-4 leading-2">Beragam varian produk terbaik dari supplier dan importir ternama kami sediakan untuk bisnis Anda.</span>
          <span className="text-white text-[14px] md:text-[14pt] mb-8 leading-2 font-bold">Saatnya perbanyak produk di toko Anda dengan resiko minim!</span>
          <Link href="https://wa.link/eda53o" target="_blank" onClick={trackGetCatalog} className="flex self-center md:self-start">
            <div className="flex items-center justify-center gap-x-2 py-2 md:py-4 px-6 bg-jooalan-secondary rounded-full font-bold text-[18px] md:text-[20pt]">
              <Image src="/img/common/whatsapp.svg" width={32} height={32} alt="whatsapp" className="-translate-y-[1px]" />
              Dapatkan Katalog
            </div>
          </Link>
        </div>
        <div className="flex w-full sm:w-1/3 pb-8 md:pb-0 ">
          <div className="absolute -top-2 md:-top-8 rotate-[3deg] -translate-x-4 md:translate-x-0 translate-y-2 right-0 md:-right-12 p-4 md:p-0  mx-8">
            <Image src="/consignment/img/hero.png" alt="hero" width={800} height={800} />
          </div>
        </div>
      </div>
    </section>
  );
};